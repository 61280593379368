import { template as template_bc7766590e2b4ebd88b997265fea085a } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_bc7766590e2b4ebd88b997265fea085a(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
