import { template as template_4af8be0354974a63961f7557912cbf58 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4af8be0354974a63961f7557912cbf58(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
