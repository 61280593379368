import { template as template_85771baea9c64e199400f0a2aad3e2cc } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import bodyClass from "discourse/helpers/body-class";
const AddCategoryTagClasses = template_85771baea9c64e199400f0a2aad3e2cc(`
  {{#if @category}}
    {{bodyClass "category" (concat "category-" @category.fullSlug)}}
  {{/if}}

  {{#each @tags as |tag|}}
    {{bodyClass (concat "tag-" tag)}}
  {{/each}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default AddCategoryTagClasses;
