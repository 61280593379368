import { template as template_05440aaeaaf14abf844ad33636505e8d } from "@ember/template-compiler";
const FKText = template_05440aaeaaf14abf844ad33636505e8d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
